/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
  ActionResult,
  ChoiceUserInputAction,
  ChoiceUserInputBranchState,
  PitchRange,
  SessionSpeechAnalysis,
  SpeechSpeed,
  UserInputAction
} from "../../../schema";
import {minutesFromDeltaTimestamp} from "../../../utils";
import {isChoiceUserInputAction, isUserInputAction} from "../../../types";

import SessionActionDisplay from "./SessionActionDisplay";

import cn from "classnames";
import classes from "./SessionUserInputActionDisplay.module.css";
import {
  AlertCircleOutlineIcon,
  CheckmarkFilledCircleIcon,
  CheckmarkIcon,
  CrossCircleIcon,
  CrossIcon,
  KeyIcon,
  PlayCircleIcon,
} from "../../../ui/icons";
import WithTooltip from "../../../ui/tooltip";
import {gql} from "@apollo/client";
import {phraseToText} from "../../Phrase";
import {useAudio} from "../../../providers/audio";

type props = React.ComponentProps<"div"> & {
  action: UserInputAction | ChoiceUserInputAction
  result: ActionResult,
  noSpeech?: boolean,
  customScore?: boolean,
  streamingAnalysis?: SessionSpeechAnalysis,
}

type labelProps = React.ComponentProps<"div"> & {
  variant?: "warning" | "success" | "failed",
  icon: React.ReactNode,
  text: string,
}

const SessionUserInputActionDisplay = function (
  {action, result, noSpeech, customScore, streamingAnalysis, className, ...props}: props
) {
  const {t} = useTranslation();

  const icon = useMemo(() => (
    customScore ? (
      <></>
    ) : (
      result.passed ? (
        <CheckmarkFilledCircleIcon className={cn(classes.icon, classes.passed)}/>
      ) : (
        <CrossCircleIcon className={cn(classes.icon, classes.failed)}/>
      )
    )
  ), [result, customScore])

  const {audioManager} = useAudio();

  const onPlayClick = useCallback((e: React.MouseEvent) => {
    if (!result.audio) {
      console.warn("Cannot play user record: no mediafile");
      return;
    }

    audioManager.getPlayback("results")!
      .play(result.audio)
      .catch(console.error);
  }, [audioManager, result.audio]);

  const selectedBranch = useMemo(() => {
    if (isChoiceUserInputAction(action) && (result.branchId !== undefined)) {
      return action.branches.find(branch => (branch.id === result.branchId))
    } else {
      return undefined
    }
  }, [action, result])

  const mainText = useMemo(() => {
    switch (action.__typename) {
      case "UserInputAction":
        if (action.freeSpeech && result.freeSpeechMessage) {
          return (
            <span>
              {result.freeSpeechMessage}
            </span>
          )
        }
        if (action.hintText !== "") {
          return (
            <span>
              {phraseToText(action.hintText)}
              <WithTooltip as={"span"} helpText={action.expectedText}><KeyIcon/></WithTooltip>
            </span>
          )
        }
        return phraseToText(action.expectedText)
      case "ChoiceUserInputAction":
        if (selectedBranch === undefined) {
          return ""
        }
        return phraseToText(selectedBranch.userInput.expectedText)
      default:
        throw new Error(`Trying to show result for unsupported User Action type: "${action.__typename}"`)
    }
  }, [action, selectedBranch, result])

  const subItem = useMemo(() => {
    if (isChoiceUserInputAction(action)) {

      if (customScore && result.isSkipped) {
        return (
          <span className={cn(classes.subText, classes.correctBranches)}>
            {t("components.SessionUserInputActionDisplay.choices")}
            {action.branches
              .map(branch => (
                <span key={branch.id}>
                  {phraseToText(branch.userInput.expectedText)}
                </span>
              ))
            }
          </span>
        )
      }

      if (!customScore && selectedBranch?.state !== ChoiceUserInputBranchState.CORRECT) {
        return (
          <span className={cn(classes.subText, classes.correctBranches)}>
            {t("components.SessionUserInputActionDisplay.correctChoices")}
            {action.branches
              .filter(branch => branch.state === ChoiceUserInputBranchState.CORRECT)
              .map(branch => (
                <span key={branch.id}>
                  {phraseToText(branch.userInput.expectedText)}
                </span>
              ))
            }
          </span>
        )
      }
    }
  }, [action, selectedBranch, customScore, result.isSkipped, t])

  const preText = useMemo(() => {
    if (isUserInputAction(action)) {
      if (action.freeSpeech) {
        return t("components.SessionUserInputActionDisplay.freeSpeech")
      }
    }
  }, [action, t])

  const time = useMemo(() => minutesFromDeltaTimestamp(result.duration, true), [result.duration])
  const after = useMemo(() => {
      return (
        <>
          {time}
          {result.audio && !noSpeech && (
            <div tabIndex={0} className={classes.playBtn} onClick={onPlayClick}>
              <PlayCircleIcon/>
            </div>
          )}
        </>
      )
  }, [onPlayClick, time, result, noSpeech]);

  const speechSpeed = streamingAnalysis?.speechSpeed ?? result.speechSpeed
  const pitchRange = streamingAnalysis?.pitchRange ?? result.pitchRange

  const resultDetails = useMemo(() => {
    const reasons: Record<string, labelProps> = {};

    if ((result.attempts > 1) || !result.passed) {
      reasons.attempts = {
        text: ((isSkipped, attempts) => {
          if (isSkipped) {
            return attempts === 0
              ? t("components.SessionUserInputActionDisplay.failReasons.skippedWithoutAttempts")
              : t("components.SessionUserInputActionDisplay.failReasons.skippedAfterManyAttempts", {attempts})
          } else {
            if (customScore) {
              return "";
            }
            if (attempts < 2) {
              return t("components.SessionUserInputActionDisplay.failReasons.wrongChoice");
            } else {
              return t("components.SessionUserInputActionDisplay.failReasons.tooManyAttempts", {attempts});
            }
          }
        })(result.isSkipped, result.attempts),
        icon: customScore ? undefined : result.passed ? <CheckmarkIcon/> : <CrossIcon/>
      }
      // Hack for skippedAfterManyAttempts/tooManyAttempts plurals parsing
      t("components.SessionUserInputActionDisplay.attempts", {count: result.attempts})
    }

    if (result.usedHint && !customScore) {
      reasons.usedHint = {
        text: t("components.SessionUserInputActionDisplay.failReasons.usedHint"),
        icon: <KeyIcon/>
      }
    }

    if (speechSpeed) {
      reasons["speechSpeed"] = {
        variant: speechSpeed === SpeechSpeed.REASONABLE ? "success" : "warning",
        text: ((speed) => {
          switch (speed) {
            case SpeechSpeed.FAST:
              return t("components.SessionUserInputActionDisplay.tooFast")
            case SpeechSpeed.SLOW:
              return t("components.SessionUserInputActionDisplay.tooSlow")
            case SpeechSpeed.REASONABLE:
              return t("components.SessionUserInputActionDisplay.speedReasonable")
            default:
              return "[NotImplemented]";
          }
        })(speechSpeed),
        icon: speechSpeed === SpeechSpeed.REASONABLE ? <CheckmarkIcon/> : <AlertCircleOutlineIcon/>
      }
    }

    if (pitchRange) {
      reasons["pitchRange"] = {
        variant: pitchRange === PitchRange.REASONABLE ? "success" : "warning",
        text: ((pitchRange) => {
          switch (pitchRange) {
            case PitchRange.MONOTONIC:
              return t("components.SessionUserInputActionDisplay.tooMonotonic")
            case PitchRange.EXPRESSIVE:
              return t("components.SessionUserInputActionDisplay.tooExpressive")
            case PitchRange.REASONABLE:
              return t("components.SessionUserInputActionDisplay.pitchReasonable")
            default:
              return "[NotImplemented]";
          }
        })(pitchRange),
        icon: pitchRange === PitchRange.REASONABLE ? <CheckmarkIcon/> : <AlertCircleOutlineIcon/>
      }
    }

    return reasons
  }, [result, customScore, pitchRange, speechSpeed, t])

  return (
    <SessionActionDisplay variant="user" icon={icon} after={after} {...props}>
      {preText && <span className={classes.preText}>{preText}</span>}
      {mainText}
      {subItem}
      {resultDetails && (
        <div className={classes.labelsWrapper}>
          <div className={classes.column}>
            {resultDetails.attempts && <LabelsRow variant="failed" {...resultDetails.attempts}/>}
          </div>
          <div className={classes.column}>
            {resultDetails.isSkipped && <LabelsRow variant="failed" {...resultDetails.isSkipped}/>}
            {resultDetails.usedHint && <LabelsRow variant="failed" {...resultDetails.usedHint}/>}
            {resultDetails.speechSpeed && !noSpeech && <LabelsRow {...resultDetails.speechSpeed}/>}
            {resultDetails.pitchRange && !noSpeech && <LabelsRow {...resultDetails.pitchRange}/>}
          </div>
        </div>
      )}
    </SessionActionDisplay>
  )
}

const LabelsRow = ({icon, variant, text, className, ...props}: labelProps) => (
  <div
    className={
      cn(classes.label, classes[variant ?? "warning"], className)}
    {...props}>
    {icon}
    <span className={classes.labelText}>{text}</span>
  </div>
)

SessionUserInputActionDisplay.fragments = {
  root: gql`
    fragment SessionUserInputActionDisplay on Action {
      id
      ... on UserInputAction {
        hintText
        expectedText
        freeSpeech
      }

      ... on ChoiceUserInputAction {
        id
        branches {
          id
          state
          userInput {
            id
            expectedText
          }
        }
      }
    }`,

  actionResult: gql`
    fragment  SessionUserInputActionDisplayActionResult on ActionResult {
      passed
      branchId
      audio

      duration
      attempts
      isSkipped
      usedHint

      speechSpeed
      pitchRange

      freeSpeechMessage
    }
  `,
}

export default SessionUserInputActionDisplay;
