import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function pause() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    analyticsSendEvent("playerPause", {
      sessionId: state.sessionId!,
    });

    dispatch({
      type: "pause"
    });
  }
}
