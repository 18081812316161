import React, {useMemo} from "react"

export enum PhraseVariant {
  DEFAULT,
  DISPLAY_HINTS,
  HIDDEN,
}

type props = Omit<React.ComponentProps<"span">, "children" | "placeholder"> & {
  text: string
  variant: PhraseVariant
  placeholder?: React.ReactNode
  italic?: boolean
}

export const supportedPhraseTags = ["<k>", "</k>", "<h>", "</h>"]

export function phraseToText(phrase: string) {
  return phrase.replace(/(<\/?[hk]>)/gs, "")
}

const phraseTagsRegexp = /(?:<k>(.*?)<\/k>)|(?:<h>(.*?)<\/h>)/gm

enum PHRASE_PART_TYPE {
  PLAIN = "plain",
  HIGHLIGHT = "highlight",
  KEYPHRASE = "keyphrase"
}

export function parseTextToPhraseParts(text: string) {
  const regex = phraseTagsRegexp;

  let m;
  let previousIndex = 0;

  const parts: {
    type: PHRASE_PART_TYPE,
    text: string
  }[] = [];

  while ((m = regex.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m.index > previousIndex) {
      parts.push({
        type: PHRASE_PART_TYPE.PLAIN,
        text: text.substring(previousIndex, m.index)
      })
    }

    const [, keyphrase, highlight] = m;

    if (keyphrase) {
      parts.push({
        type: PHRASE_PART_TYPE.KEYPHRASE,
        text: keyphrase
      })
    }

    if (highlight) {
      parts.push({
        type: PHRASE_PART_TYPE.HIGHLIGHT,
        text: highlight
      })
    }

    previousIndex = regex.lastIndex;
  }

  if (text.length > previousIndex) {
    parts.push({
      type: PHRASE_PART_TYPE.PLAIN,
      text: text.substring(previousIndex, text.length)
    })
  }

  return parts;
}

export default function Phrase({text, variant, placeholder, italic, ...props}: props) {
  const parts = useMemo(() => parseTextToPhraseParts(text), [text]);

  const elements = useMemo(() => parts.map((part, index) => {
    const modifiedText = (() => {
      switch (variant) {
        case PhraseVariant.DEFAULT:
          return part.text;
        case PhraseVariant.DISPLAY_HINTS:
          switch (part.type) {
            case PHRASE_PART_TYPE.HIGHLIGHT:
            case PHRASE_PART_TYPE.KEYPHRASE:
              return part.text;
            case PHRASE_PART_TYPE.PLAIN:
              return masked(part.text);
            default:
              throw Error("[NotImplemented]");
          }
        case PhraseVariant.HIDDEN:
          return masked(part.text);
        default:
          /* istanbul ignore next */
          throw Error("[NotImplemented]");
      }
    })()

    switch (part.type) {
      case PHRASE_PART_TYPE.HIGHLIGHT:
        return <strong key={index}>{modifiedText}</strong>
      case PHRASE_PART_TYPE.KEYPHRASE:
        return <b key={index}>{modifiedText}</b>
      case PHRASE_PART_TYPE.PLAIN:
        return modifiedText;
      default:
        throw Error("[NotImplemented]");
    }
  }), [parts, variant])

  if ((elements.length === 0) && placeholder) {
    return <>{placeholder}</>;
  }

  return (
    italic ? (
      <span translate="no" {...props}><i>{elements}</i></span>
    ) : (
      <span translate="no" {...props}>{elements}</span>
    )

  )
}

function masked(s: string): string {
  return s.replace(/[^ -.,;\-:!–)(?]/g, ".")
}

export function getTextWithStressMarks(text: string) {
  return text.replace(/(\+)(\w|[а-яА-Я])/gm, "$2\u0301")
}
