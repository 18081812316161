/* eslint-disable max-lines */
import {PlayableAction, PlayerReduxState} from "./types";
import {initialReduxState} from "./store";
import {PlayerReduxAction} from "./rootActions";
import {
  ChoiceUserInputBranch,
  ChoiceUserInputBranchState,
  LessonScenarioAfterAction,
  MutationEducationSessionFinishScenarioArgs,
  RandomAvatarActionBranch,
  Scalars
} from "../../schema";
import {getCurrentScenario, getCurrentMaxIndex, getCurrentAction, isExamStage, getMaxLevel} from "./selectors";
import {analyticsSendEvent} from "../../libs/analytics";
import {scenarioFinishMutation} from "./graphql";
import {isAvatarAction, isSystemAction} from "../../types";
import {isIPhone, isSafari} from "../../browser";
import {getBranch} from "./utils";


export default function rootReducer(state: PlayerReduxState | undefined, action: PlayerReduxAction): PlayerReduxState {
  if (state === undefined) {
    throw new Error("Cannot initialize without predefined state")
  }

  const iPhone = isIPhone()
  const safari = isSafari()

  switch (action.type) {
    case "start":
      return {
        ...initialReduxState,

        client: state.client,
        audioManager: state.audioManager,
        toggleRecorder: state.toggleRecorder,
        raiseProblem: state.raiseProblem,

        lessonId: state.lessonId,
        lesson: state.lesson,
        scenarios: state.scenarios,
        isShared: state.isShared,
        isPreview: state.isPreview,
        isExternal: state.isExternal,
        externalUserId: state.externalUserId,
        utm: state.utm,
        recognitionPipeline: iPhone || safari ? "old" : "new",

        ...action.payload,
      }

    case "next": {
        const actions = getCurrentScenario(state).actions
        const totalActions = actions.length;
        if (totalActions === 0) {
          return state;
        }
        state = {...state, isHintConfirmation: false, isAutoUseHintOffered: false};

        if (state.branchId) {
          const rootAction = getCurrentScenario(state).actions[state.index];
          const branch = rootAction ? getBranch(rootAction, state.branchId) : undefined;
          const branchActions = branch ? getBranchActions(branch) : [];

          const nextBranchIndex = branchActions ? _next(branchActions, state.level, state.branchIndex!) : undefined;

          if (nextBranchIndex === undefined) {
            state = {
              ...state,
              branchId: undefined,
              branchIndex: undefined,
              chosenBranches: new Set([...Array.from(state.chosenBranches), state.branchId])
            }
            if (branch && branch.__typename === "ChoiceUserInputBranch") {
              switch (branch?.state) {
                case ChoiceUserInputBranchState.CORRECT:
                  // go to next action calc
                  break;
                case ChoiceUserInputBranchState.INCORRECT:
                  // stay on current action
                  if (!state.lastUserInputResult?.ok) {
                    return state;
                  }
                  break;
                case ChoiceUserInputBranchState.INTERRUPT:
                  state["interrupted"] = true;
                  return state;
                default:
                  console.error("Invalid ChoiceUserInputBranchState");
                  break;
              }
            }
          } else {
            state["branchIndex"] = nextBranchIndex;
            return state;
          }
        }

        state = {
          ...state,
          currentActionAttempts: 0,
          chosenBranches: new Set()
        };

        const nextIndex = _next(getCurrentScenario(state).actions, state.level, state.index);
        if (nextIndex === undefined) {
          const current = getCurrentScenario(state);

          if (current.isMain) {
            // next level
            return {
              ...state,
              level:state.level + 1,
              scenarioIndex: 0,
              index: -1,
              mainScenarioLastIndex: -1,
              exposedActions: []
            }
          } else {
            const prevScenarioId = getCurrentScenario(state).id;
            let newState = state;

            const mainNextIndex = _next(getCurrentScenario(
              {...state, scenarioIndex: 0}).actions, state.level, state.mainScenarioLastIndex);
            //back to main scenario
            switch (current.afterAction) {
              case LessonScenarioAfterAction.RETURN:
                newState = {
                  ...state,
                  scenarioIndex: 0,
                  index: state.mainScenarioLastIndex,
                  exposedActions: []
                }
                break;
              case LessonScenarioAfterAction.NEXT:
                if (mainNextIndex === undefined) {
                  newState = {
                    ...state,
                    level:state.level + 1,
                    scenarioIndex: 0,
                    index: -1,
                    mainScenarioLastIndex: -1,
                    exposedActions: []
                  }
                } else {
                  newState = {
                    ...state,
                    scenarioIndex: 0,
                    index: mainNextIndex,
                    exposedActions: []
                  }
                }
              break;
              case LessonScenarioAfterAction.INTERRUPT:
                state["interrupted"] = true;
                newState = state
                break;
              case LessonScenarioAfterAction.FINISH:
                newState = {
                  ...state,
                  scenarioIndex: 0,
                  level: getMaxLevel(state) + 1
                }
                break;
              default:
                console.error("Invalid LessonScenarioAfterAction");
                break;
            }

            newState.client.mutate<{ok: Scalars["Boolean"] }, MutationEducationSessionFinishScenarioArgs>({
              mutation: scenarioFinishMutation,
              variables: {
                actionId: getCurrentAction(newState)?.id,
                level: Math.min(getMaxLevel(newState), newState.level),
                scenarioId: prevScenarioId,
                sessionId: newState.sessionId!,
              }
            }).then((result) => {
              if (!result || !result.data || !result.data.ok) {
                throw new Error("Cannot finish scenario");
              }
            })

            return newState;
          }
        }

        const maxIndexes = [...state.maxIndexes];
        const prevMaxIndex = getCurrentMaxIndex(state);
        maxIndexes[state.scenarioIndex] = prevMaxIndex ? Math.max(nextIndex, prevMaxIndex) : nextIndex;
        return {
          ...state,
          index: nextIndex,
          mainScenarioLastIndex: getCurrentScenario(state).isMain ? nextIndex : state.mainScenarioLastIndex,
          maxIndexes
        }
      }

    case "back":
      for (let i = state.index - 1; i > 0; i--) {
        if (getCurrentScenario(state).actions[i].id === action.payload?.actionId) {
          return {
            ...state,
            isHintConfirmation: false,
            index: i,
          }
        }
      }

      return {
        ...state,
        isHintConfirmation: false,
        index: 0,
      }
    case "navigate": {

      return {
        ...state,
        isHintConfirmation: false,
        index: action.payload?.index ?? state.index,
      }
    }

    case "hint":
      if (state.isHintConfirmation || !isExamStage(state)) {
        return {
          ...state,
          isHintConfirmation: false,
          isAutoUseHintOffered: true,
          exposedActions: [...state.exposedActions, action.payload.actionId]
        }
      }
      return {
        ...state,
        isHintConfirmation: true,
        isAutoUseHintOffered: action.payload.isAutoUseHintOffered,
      }

    case "cancelConfirmHint":
      return {
        ...state,
        isHintConfirmation: false,
      }

    case "skipLevel":
      return {
        ...state,
        ...action.payload,
      }

    case "update":
      return {
        ...state,
        ...action.payload,
      }

    case "interrupt":
      return {
        ...state,

        interrupted: true,
        interruptReason: action.payload?.interruptReason ?? null,
      }

    case "startScenario": {
      state = {...state, isHintConfirmation: false};

      analyticsSendEvent("startScenario", {
        sessionId: state.sessionId!,
        scenarioId: action.payload.scenarioId
      });

      const scenarioIndex = state.scenarios.findIndex(({id}) => id === action.payload.scenarioId)
      if (scenarioIndex === -1) {
        console.error(
          `Trying to start scenario with id "${action.payload.scenarioId}", which is not present in lesson`
        )
      }
      if (state.scenarios[scenarioIndex].actions.length === 0) {
        return state;
      }

      const current = getCurrentScenario(state);
      if (!current.isMain) {

        state.client.mutate<{ok: Scalars["Boolean"] }, MutationEducationSessionFinishScenarioArgs>({
          mutation: scenarioFinishMutation,
          variables: {
            actionId: getCurrentAction(state)?.id,
            level: Math.min(getMaxLevel(state), state.level),
            scenarioId: current.id,
            sessionId: state.sessionId!,
          }
        }).then((result) => {
          if (!result || !result.data || !result.data.ok) {
            throw new Error("Cannot finish scenario");
          }
        })
      }

      return {
        ...state,
        scenarioIndex,
        index: 0,
        branchIndex: -1
      }
    }

    case "notPassed": {
      return {
        ...state,
        recognitionPipeline: state.recognitionPipeline === "old" || state.currentActionAttempts > 0 ? "old" : "new",
        currentActionAttempts: state.currentActionAttempts + 1
      }
    }

    case "pause": {
      return {
        ...state,
        onPause: true,
      }
    }

    case "unpause": {
      return {
        ...state,
        onPause: false,
      }
    }

    default:
      return state;
  }
}

function getBranchActions(branch: ChoiceUserInputBranch | RandomAvatarActionBranch): PlayableAction[] | undefined {
  if (branch.__typename === "ChoiceUserInputBranch") {
    return [branch.avatarReaction, branch.systemReaction].filter(action => !!action) as PlayableAction[];
  }

  if (branch.__typename === "RandomAvatarActionBranch") {
    return [branch.avatarAction] as PlayableAction[];
  }

  return undefined
}

function _next(actions: PlayableAction[], level: number, index: number): number | undefined {
  const totalActions = actions.length;

  for (let i = index + 1; i <= totalActions; i++) {
    if (i >= totalActions) {
      return undefined;
    }

    let nextAction = actions[i];

    if (level > 1 && (isSystemAction(nextAction) || isAvatarAction(nextAction)) && nextAction.onlyFirstLevel) {
      continue
    }

    return i;
  }
}
