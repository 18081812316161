/* eslint-disable max-lines */
import React, {ComponentProps, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

import Button from "../../ui/button";
import {
  AlertCircleOutlineIcon,
  ArrowForward,
  BanIcon,
  ThreeDimensionsOffIcon,
  ThreeDimensionsIcon,
  CheckmarkIcon,
  CloseIcon,
  CompassIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  HelpCircleIcon,
  IconType,
  KeyIcon,
  LanguageIcon,
  MenuIcon,
  PlayForwardIcon,
  RefreshIcon,
  ResizeIcon,
  SendIcon,
  PauseIcon,
  PlayIcon
} from "../../ui/icons";
import MicButton from "./MicButton";
import ActionSheet from "../../ui/actionsheet";
import {AsSpecificComponent, ListAction} from "../../ui";
import PopupPortal from "../../ui/popupportal";

import cn from "classnames"
import classes from "./Controls.module.css";

type ActionSheetProps = Omit<AsSpecificComponent<typeof PopupPortal, typeof ActionSheet>, "className" | "as">

export const MobileActionSheet = (props: ActionSheetProps) => {
  return <PopupPortal as={ActionSheet} className={classes.hideOnDesktop} {...props}/>
}

type ButtonProps = {
  main?: boolean
}

type IconButtonProps = Omit<ComponentProps<typeof Button>, "className" | "children" | "as"> & ButtonProps & {
  icon: IconType
}

const IconButton = ({icon: Icon, main, ...props}: IconButtonProps) => (
  <Button
    className={cn(classes.iconBtn, {[classes.mainBtn]: main})}
    {...props}
  ><Icon/></Button>
)

type GenericActionProps = ButtonProps & Pick<ComponentProps<ListAction>, "title"> & {
  as: ListAction | typeof IconButton,
}

const SendButton = ({canSend, handleSend, ...props}: ButtonProps & {
  canSend: boolean,
  handleSend: () => void,
}) => (
  <IconButton
    disabled={!canSend}
    onClick={canSend ? handleSend : undefined}
    icon={SendIcon}
    {...props}
  />
)

const SkipButton = ({canSkip, handleSkip, ...props}: ButtonProps & {
  canSkip: boolean,
  handleSkip: () => void,
}) => (
  <IconButton
    disabled={!canSkip}
    onClick={canSkip ? handleSkip : undefined}
    icon={PlayForwardIcon}
    {...props}
  />
)

const FullscreenAction = ({as: Action, onClick}: {
  as: ListAction,
  onClick?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <Action
      onClick={onClick}
      icon={ResizeIcon}
      title={t("player.menuActions.fullscreen")}
    />
  )
}

const HelpAction = ({as: Action, onClick}: {
  as: ListAction,
  onClick?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <Action
      onClick={onClick}
      icon={HelpCircleIcon}
      title={t("player.menuActions.help")}
    />
  )
}

const ExtraOptionsButton = () => (
  <IconButton
    icon={EllipsisVerticalIcon}
  />
)

const SkipAction = ({handleSkip}: {
  handleSkip: () => void,
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={handleSkip}
      icon={PlayForwardIcon}
      title={t("common.skip")}
    />
  )
}

const SkipLevelAction = ({handleSkip}: {
  handleSkip: () => void,
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={handleSkip}
      icon={PlayForwardIcon}
      title={t("common.skipLevel")}
    />
  )
}


const PassButton = ({handlePass, ...props}: ButtonProps & {
  handlePass: () => void,
}) => (
  <IconButton
    onClick={handlePass}
    icon={ArrowForward}
    {...props}
  />
)

const WatchButton = ({openMedia, ...props}: ButtonProps & {
  openMedia?: () => void,
}) => (
  <IconButton
    onClick={openMedia}
    icon={EyeIcon}
    {...props}
  />
)

const RepeatButton = ({canRepeat, handleRepeat, ...props}: ButtonProps & {
  canRepeat: boolean,
  handleRepeat: () => void,
}) => (
  <IconButton
    disabled={!canRepeat}
    onClick={canRepeat ? handleRepeat : undefined}
    icon={RefreshIcon}
    {...props}
  />
)

const RepeatAction = ({handleRepeat}: {
  handleRepeat: () => void,
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={handleRepeat}
      icon={RefreshIcon}
      title={t("player.menuActions.repeat")}
    />
  )
}

const HintButton = ({canUseHint, handleUseHint, ...props}: ButtonProps & {
  canUseHint: boolean,
  handleUseHint: () => void,
}) => (
  <IconButton
    disabled={!canUseHint}
    onClick={canUseHint ? handleUseHint : undefined}
    icon={KeyIcon}
    {...props}
  />
)

const HintAction = ({handleUseHint}: {
  handleUseHint: () => void,
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={handleUseHint}
      icon={KeyIcon}
      title={t("player.menuActions.useHint")}
    />
  )
}

const NavigationButton = ({handleNavigate, ...props}: ButtonProps & {
  handleNavigate: () => void,
}) => (
  <IconButton
    onClick={handleNavigate}
    icon={CompassIcon}
    {...props}
  />
)

const NavigationAction = ({showNavigation}: {
  showNavigation?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={showNavigation}
      icon={CompassIcon}
      title={t("player.menuActions.showNavigation")}
      notCloseOnClick
    />
  )
}

const MenuButton = ({openMenu, ...props}: ButtonProps & {
  openMenu?: () => void,
}) => (
  <IconButton
    onClick={openMenu}
    icon={MenuIcon}
    {...props}
  />
)

const CancelAction = ({as: Action, onCancel, ...props}: GenericActionProps & {
  onCancel?: () => void,
}) => {
  const {t} = useTranslation();

  return (
    <Action
      onClick={onCancel}
      icon={CloseIcon}
      title={t("common.cancel")}
      {...props}
    />
  )
}


const ConfirmAction = ({as: Action, onConfirm, ...props}: GenericActionProps & {
  onConfirm?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <Action
      onClick={onConfirm}
      icon={CheckmarkIcon}
      title={t("common.confirm")}
      {...props}
    />
  )
}

const SwitchAvatarModeAction = ({handleSwitch, mode}: {
  handleSwitch?: () => void,
  mode: "2D" | "3D"
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={handleSwitch}
      icon={mode === "2D" ? ThreeDimensionsOffIcon : ThreeDimensionsIcon}
      title={t("player.menuActions.switchAvatarMode", {
        mode: mode
      })}
    />
  )
}

const ChangeLanguageAction = ({changeLanguage}: {
  changeLanguage?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <ActionSheet.Action
      onClick={changeLanguage}
      icon={LanguageIcon}
      title={t("player.menuActions.changeLanguage")}
      notCloseOnClick
    />
  )
}


type RecordButtonProps = Pick<ComponentProps<typeof MicButton>,
  "disabled" | "recordingClassName" | "isMicAlertMuted" | "muteMicAlert" | "applyTimeout">

const RecordButton = (({...props}: RecordButtonProps) => (
  <MicButton
    className={cn(classes.iconBtn, classes.mainBtn)}
    {...props}
  />
))

const PauseButton = ({onClick, ...props}: ButtonProps & {
  onClick?: () => void,
}) => (
    <IconButton
      main
      onClick={onClick}
      icon={PauseIcon}
      {...props}
    />
)

const UnpauseButton = ({onClick, ...props}: ButtonProps & {
  onClick?: () => void,
}) => (
    <IconButton
      main
      onClick={onClick}
      icon={PlayIcon}
      {...props}
    />
)

const EndLessonAction = ({backUrl}: {
  backUrl?: string
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const endLesson = useCallback(() => {
    backUrl && history.push(backUrl);
  }, [backUrl, history])
  return (
    <ActionSheet.Action
      onClick={endLesson}
      icon={BanIcon}
      title={t("player.endLesson")}
      notCloseOnClick
    />
  )
}

const SendReportAction = ({as: Action, onClick}: {
  as: ListAction,
  onClick?: () => void
}) => {
  const {t} = useTranslation();

  return (
    <Action
      onClick={onClick}
      icon={AlertCircleOutlineIcon}
      title={t("player.menuActions.sendReport")}
    />
  )
}

export {
  IconButton,
  SkipButton, SkipAction,
  SkipLevelAction,
  FullscreenAction,
  SendReportAction,
  HelpAction,
  PassButton,
  WatchButton,
  RepeatButton, RepeatAction,
  HintButton, HintAction,
  NavigationButton,
  NavigationAction,
  MenuButton,
  CancelAction,
  ConfirmAction,
  SwitchAvatarModeAction,
  ChangeLanguageAction,
  RecordButton,
  EndLessonAction,
  ExtraOptionsButton,
  SendButton,
  PauseButton,
  UnpauseButton,
}
