import {PlayerReduxDispatch, PlayerReduxState} from "../types";
import {analyticsSendEvent} from "../../../libs/analytics";

export default function unpause() {
  return (dispatch: PlayerReduxDispatch, getState: () => PlayerReduxState) => {
    const state = getState();

    analyticsSendEvent("playerUnpause", {
      sessionId: state.sessionId!,
    });

    dispatch({
      type: "unpause"
    });
  }
}
