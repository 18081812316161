/* eslint-disable max-lines */
import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import isEqual from "lodash/isEqual";
import {AvatarPlayerRef} from "./AvatarPlayer";
import {useAudioPreloader} from "../../providers/audioPreloader";
import {useMediaRecorder} from "../../providers/mediaRecorder";
import {isAvatarAction, isSystemAction} from "../../types";
import {useModal} from "../ModalProvider";
import {useGuide} from "../../providers/guide";
import {useSendBugReport} from "../../hooks/telemetry";
import {useCurrentUser} from "../../App.context";
import useAutoResetState from "../../hooks/useAutoResetState";
import {LessonInputMode} from "../../schema";

// Components
import {
  ChangeLanguageAction,
  EndLessonAction,
  ExtraOptionsButton,
  FullscreenAction,
  SendReportAction,
  HelpAction,
  HintAction,
  HintButton,
  MenuButton,
  NavigationAction,
  NavigationButton,
  PassButton,
  RecordButton,
  RepeatAction,
  RepeatButton,
  SkipAction,
  SkipButton,
  SwitchAvatarModeAction,
  WatchButton,
  MobileActionSheet,
  IconButton,
  ConfirmAction,
  CancelAction,
  SkipLevelAction,
  SendButton,
  PauseButton,
  UnpauseButton
} from "./Controls";
import ActionSheet from "../../ui/actionsheet";
import PopupPortal from "../../ui/popupportal";
import Widget from "../../ui/widget";
import LanguageActionsList from "./LanguageActionsList";
import NavigationList from "./NavigationList";
import NavigationListAction from "./NavigationListAction";
import NavigationListElement from "./NavigationListElement";
import BugReportModal from "../system/BugReportModal";
import Button from "../../ui/button";
import DropdownList from "../../ui/dropdownlist";
import Scroll from "../../ui/scroll";

import cn from "classnames";
import classes from "./ControlsBar.module.css";

// Redux
import {
  canNavigate,
  canRepeat,
  canSkip,
  canUseHint,
  getCurrentAction,
  getSessionId,
  isHintConfirmation,
  isIntroStage,
  isPreview,
  isLastUserInputError,
  getExternalUserId,
} from "../../redux/player/selectors";
import {PlayerReduxState} from "../../redux/player/types";
import {isAnyUserInputAction, isFreeSpeech} from "../../redux/player/utils";
import {hint, passIntro, repeat, skipAction, cancelConfirmHint, skipLevel, passUserInput, pause, unpause
} from "../../redux/player/actions";
import canSkipLevel from "../../redux/player/selectors/canSkipLevel";
import Input from "../../ui/input";
import {useFormState} from "../../hooks/useFormState";


type props = Pick<React.ComponentProps<"div">, "className"> & {
  allowFullscreen?: boolean,
  toggleFullscreen?: () => void,
  openFullscreenMedia?: () => void,
  mediaIsViewed: boolean,
  avatarLoaded?: boolean,
  avatarMode?: AvatarPlayerRef["mode"],
  switchAvatarMode?: AvatarPlayerRef["switchMode"],
  backUrl?: string,
} & Pick<React.ComponentProps<typeof RecordButton>, "recordingClassName">

const ControlsBar = ({
                       allowFullscreen,
                       toggleFullscreen,
                       openFullscreenMedia,
                       mediaIsViewed,
                       avatarLoaded,
                       avatarMode,
                       switchAvatarMode,
                       backUrl,
                       className,
                       recordingClassName
                     }: props) => {

  const {t} = useTranslation();
  const user = useCurrentUser();

  const {
    isIntro,
    isAnyUserInputAction,
    isMediaViewRequired,
    canUseHint,
    isHintConfirmation,
    canNavigate,
    canRepeat,
    canSkip,
    isLastUserInputError,
    isPreview,
    externalUserId,
    sessionId,
    actionId,
    canSkipLevel,
    isFreeSpeech,
    inputMode,
    alwaysCanSkipSteps,
    isNonUserStep,
    isNonUserStepPaused,
  } = useSelector(ControlsBar.selector, isEqual);

  const dispatch = useDispatch();
  const handleUseHint = useCallback(() => dispatch(hint()), [dispatch]);
  const handleCancleConfirmHint = useCallback(() => dispatch(cancelConfirmHint()), [dispatch]);
  const handleRepeat = useCallback(() => dispatch(repeat()), [dispatch]);
  const handleSkip = useCallback(() => dispatch(skipAction()), [dispatch]);
  const handleSkipLevel = useCallback(() => dispatch(skipLevel()), [dispatch]);
  const handlePassIntro = useCallback(() => dispatch(passIntro()), [dispatch]);
  const handlePause = useCallback(() => dispatch(pause()), [dispatch]);
  const handleUnpause = useCallback(() => dispatch(unpause()), [dispatch]);

  const {isLoaded: audioLoaded, isIntroLoaded: introAudioLoaded} = useAudioPreloader();
  const {hasPermission, isRecording} = useMediaRecorder();

  const sendBugReport = useSendBugReport();
  const {add: addModal} = useModal();

  const [isMicAlertMuted, setMicAlertMuted] = useAutoResetState<boolean>(false, 30 * 1000);

  const muteMicAlert = useCallback(() => setMicAlertMuted(true), [setMicAlertMuted])

  useEffect(() => {
    setMicAlertMuted(true);
  }, [setMicAlertMuted, actionId])

  const onSendReportButtonClick = useCallback(() => {
    const onBugReportSend = (message: string, attachment?: File, contact?: string) => {
      sendBugReport({message, attachment, contact, sessionId, actionId, location: "controlsBar"})
      reportModal.remove();
    }

    const reportModal = addModal({
      header: <BugReportModal.Header/>,
      content: (
        <BugReportModal.Content
          onBugReportSend={onBugReportSend}
        />
      ),
      stopCloseOnOuterClick: true,
    })
  }, [addModal, sendBugReport, sessionId, actionId]);

  const {open: openGuide} = useGuide();

  const onHelpButtonClick = useCallback(() => {
    openGuide("player.main");
  }, [openGuide]);

  const hideRightButtonContainer = isHintConfirmation;

  const mainButton = useMemo(() => {
    if (!avatarLoaded || !audioLoaded || !hasPermission || !sessionId) {
      return
    }
    if (isIntro && introAudioLoaded) {
      return (
        <PassButton handlePass={handlePassIntro} main/>
      )
    }
    if (isHintConfirmation) {
      return (
        <CancelAction as={IconButton} onCancel={handleCancleConfirmHint} main/>
      )
    }
    if (isMediaViewRequired) {
      return !mediaIsViewed ? (
        <WatchButton openMedia={openFullscreenMedia} main/>
      ) : (
        <PassButton handlePass={handleSkip} main/>
      )
    }
  }, [
    avatarLoaded, audioLoaded, hasPermission, introAudioLoaded, isIntro, isHintConfirmation, handlePassIntro,
    isMediaViewRequired, sessionId, mediaIsViewed, openFullscreenMedia, handleSkip, handleCancleConfirmHint
  ])

  const optionalButton = useMemo(() => {
    if (isHintConfirmation) {
      return (<ConfirmAction as={IconButton} onConfirm={handleUseHint}/>)
    }
    if (!avatarLoaded) {
      return (<CancelAction as={IconButton} onCancel={switchAvatarMode}/>)
    }
    if (!audioLoaded) {
      return;
    }
    if (isMediaViewRequired && mediaIsViewed) {
      return (<WatchButton openMedia={openFullscreenMedia}/>)
    }
  }, [avatarLoaded, audioLoaded, switchAvatarMode, isMediaViewRequired,
    mediaIsViewed, openFullscreenMedia, isHintConfirmation, handleUseHint])

  const [isCtxMenuVisible, setCtxMenuVisibility] = useState<boolean>(false);

  const showCtxMenu = useCallback(() => {
    setCtxMenuVisibility(true)
  }, [])

  const hideCtxMenu = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation();
    setCtxMenuVisibility(false)
  }, [])

  const [languagesVisible, setLanguagesVisibility] = useState(false)

  const showLanguages = useCallback(() => setLanguagesVisibility(true), [])
  const onLanguageSelection = useCallback(() => {
    setLanguagesVisibility(false);
    setCtxMenuVisibility(false);
  }, [])
  const hideLanguages = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLanguagesVisibility(false);
  }, [])

  const [navigationVisible, setNavigationVisibility] = useState(false)

  const showNavigation = useCallback(() => setNavigationVisibility(true), [])
  const onNavigationSelection = useCallback(() => {
    setNavigationVisibility(false);
    setCtxMenuVisibility(false);
  }, [])
  const hideNavigation = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setNavigationVisibility(false);
  }, [])

  const canUserSkip: boolean = (user?.isDeveloper || user?.isStaff || isPreview) && !externalUserId;
  const canSkipNonUserSteps: boolean = alwaysCanSkipSteps! && !isAnyUserInputAction && !isMediaViewRequired;
  const showSkipAction: boolean = canSkip && (isLastUserInputError || canUserSkip || canSkipNonUserSteps);
  const isTextInput: boolean = inputMode === LessonInputMode.TEXT;

  const ctxMenu = useMemo(() => {
    const actions: ReactNode[] = [];

    if (avatarLoaded && audioLoaded && sessionId) {
      if (showSkipAction) {
        actions.push(<SkipAction key="skip" handleSkip={handleSkip}/>)
      }
      if (canSkipLevel) {
        actions.push(<SkipLevelAction key="skipLevel" handleSkip={handleSkipLevel}/>)
      }
      if (canUseHint) {
        actions.push(<HintAction key="hint" handleUseHint={handleUseHint}/>)
      }
      if (canRepeat) {
        actions.push(<RepeatAction key="repeat" handleRepeat={handleRepeat}/>)
      }
      if (canNavigate) {
        actions.push(<NavigationAction key="navigate" showNavigation={showNavigation}/>)
      }
    }

    const switchAvatarTo = avatarLoaded ? (avatarMode === "2D" ? "3D" : "2D") : "2D"

    return (
      <MobileActionSheet onClose={hideCtxMenu} animated>
        <Scroll as={ActionSheet.List} scrollable>
          <ActionSheet.List>
            <HelpAction as={ActionSheet.Action} onClick={onHelpButtonClick}/>
            <SendReportAction as={ActionSheet.Action} onClick={onSendReportButtonClick}/>
            {allowFullscreen && (<FullscreenAction as={ActionSheet.Action} onClick={toggleFullscreen}/>)}
          </ActionSheet.List>
          <ActionSheet.List className={classes.headerReplace}>
            <ChangeLanguageAction changeLanguage={showLanguages}/>
          </ActionSheet.List>
          <ActionSheet.List>
            <EndLessonAction backUrl={backUrl}/>
            <SwitchAvatarModeAction handleSwitch={switchAvatarMode} mode={switchAvatarTo}/>
          </ActionSheet.List>
          {(actions.length !== 0) && (
            <ActionSheet.List> {actions.map((item) => item)} </ActionSheet.List>
          )}
        </Scroll>
        <ActionSheet.Button title={t("common.cancel")} onClick={hideCtxMenu}/>
      </MobileActionSheet>
    )
  }, [
    avatarLoaded, audioLoaded, showSkipAction, handleSkip, canUseHint, handleUseHint, canRepeat, handleRepeat,
    canNavigate, showNavigation, avatarMode, showLanguages, switchAvatarMode, hideCtxMenu, backUrl, sessionId,
    allowFullscreen, toggleFullscreen, onHelpButtonClick, onSendReportButtonClick, canSkipLevel, handleSkipLevel, t
  ])

  const dropdownMenu = useMemo(() => (
    <>
      <HelpAction as={DropdownList.Action} onClick={onHelpButtonClick}/>
      <SendReportAction as={DropdownList.Action} onClick={onSendReportButtonClick}/>
      <FullscreenAction as={DropdownList.Action} onClick={toggleFullscreen}/>
    </>
  ), [onSendReportButtonClick, toggleFullscreen, onHelpButtonClick])

  const formState = useFormState<{message: string}>({
    initialValues: {
      message: ""
    },
    preventDefault: true,
    onSubmit: (data) => {
      if (data.message !== "") {
        dispatch(passUserInput({
          audioFileId: 0,
          videoFileId: null,
          fileUploadData: {
            id: "",
            recognizedText: data.message,
            uuid: "text",
            duration: 0,
            isError: false,
          }
        }))
        data.message = "";
      }
    }
  })

  return (
    <div className={cn(classes.root, className)}>
      {optionalButton && (
        <div className={classes.optionalButtonContainer}>
          {optionalButton}
        </div>
      )}
      <div className={cn(classes.buttonContainer, classes.controlButtonsContainer)}>
        {isAnyUserInputAction && (<>
          {canNavigate
            ? (<NavigationButton handleNavigate={showNavigation}/>)
            : (<RepeatButton canRepeat={canRepeat} handleRepeat={handleRepeat}/>)
          }
          <HintButton canUseHint={canUseHint} handleUseHint={handleUseHint}/>
        </>)}
      </div>
      <div className={classes.placeholder}/>

      {mainButton ?? (
        isNonUserStep ? (
          isNonUserStepPaused ? (
            <UnpauseButton
              onClick={handleUnpause}
            />
          ) : (
            <PauseButton
              onClick={handlePause}
            />
          )

        ) : (
          isTextInput ? (
            <form className={classes.inputContainer} method="post" onSubmit={formState.submitHandler}>
              <Input
                name="message"
                placeholder={t("player.messagePlaceholder")}
                value={formState.values.message ?? undefined}
                autoComplete="off"
                onChange={formState.changeHandler}
                disabled={!avatarLoaded ? true : undefined}
              >
              </Input>
            </form>
          ) : (
            <RecordButton
              disabled={!avatarLoaded ? true : undefined}
              recordingClassName={recordingClassName}
              isMicAlertMuted={isMicAlertMuted}
              muteMicAlert={muteMicAlert}
              applyTimeout={!isFreeSpeech}
            />
          )
        )
      )}

      {isTextInput && !isIntro && (
        <div className={classes.sendButtonContainer}>
          <SendButton
            canSend={formState.values.message !== ""}
            handleSend={formState.submitHandler}
          />
        </div>
      )}

      {hideRightButtonContainer && (
        <div className={classes.optionalButtonContainer}/>
      )}

      <div className={classes.buttonContainer}>
        {isIntro ? (
          <SkipButton
            canSkip={!!canSkipLevel && !!sessionId}
            handleSkip={handleSkipLevel}
          />
        ) : (
          <SkipButton
            canSkip={!!avatarLoaded && !!audioLoaded && !isRecording && showSkipAction}
            handleSkip={handleSkip}
          />
        )}

        <div className={classes.controlButtonsContainer}>
          <DropdownList
            adaptive rounded transparent size="l" className={classes.dropdown}
            content={dropdownMenu} placement="top-start" offset={[0, 8]}
          >
            <ExtraOptionsButton/>
          </DropdownList>
        </div>
      </div>

      <div className={classes.contextMenuButtonContainer}>
        <MenuButton openMenu={showCtxMenu}/>
      </div>

      {languagesVisible ? (
        <MobileActionSheet onClose={onLanguageSelection}>
          <LanguageActionsList/>
          <ActionSheet.Button title={t("common.cancel")} onClick={hideLanguages}/>
        </MobileActionSheet>
      ) : navigationVisible ? (<>
          <MobileActionSheet onClose={onNavigationSelection}>
            <ActionSheet.List scrollable><NavigationList as={NavigationListAction}/></ActionSheet.List>
            <ActionSheet.Button title={t("common.cancel")} onClick={hideNavigation}/>
          </MobileActionSheet>
          <PopupPortal className={cn(classes.hideOnMobile, classes.navigationWidgetPortal)}>
            <Widget className={classes.navigationWidget}>
              <div className={classes.actionsListHeader}>
                {t("player.navigation.chooseAction")}
              </div>
              <NavigationList onNavigate={hideNavigation} as={NavigationListElement}/>
              <Button className={classes.navigationWidgetButton} onClick={hideNavigation} color="primary">
                {t("common.cancel")}
              </Button>
            </Widget>
          </PopupPortal>
        </>
      ) : isHintConfirmation ? (
        <MobileActionSheet onClose={handleCancleConfirmHint}>
          <ActionSheet.List>
            <ActionSheet.Header
              title={t("player.confirmHint.title")}
              description={t("player.confirmHint.message")}
            />
            <ActionSheet.Button title={t("player.menuActions.useHint")} onClick={handleUseHint}/>
          </ActionSheet.List>
          <ActionSheet.Button onClick={handleCancleConfirmHint} title={t("common.cancel")}/>
        </MobileActionSheet>
      ) : (isCtxMenuVisible && ctxMenu)}
    </div>
  )
}

export default ControlsBar;

ControlsBar.selector = (state: PlayerReduxState) => {
  const currentAction = getCurrentAction(state);

  return {
    isIntro: isIntroStage(state),
    isAnyUserInputAction: !!currentAction && isAnyUserInputAction(currentAction),
    isFreeSpeech: !!currentAction && isFreeSpeech(currentAction),
    isMediaViewRequired: (isSystemAction(currentAction) || isAvatarAction(currentAction))
      && currentAction.mediaViewRequired,
    canUseHint: canUseHint(state),
    isHintConfirmation: isHintConfirmation(state),
    canNavigate: canNavigate(state),
    canRepeat: canRepeat(state),
    canSkip: canSkip(state),
    isLastUserInputError: isLastUserInputError(state),
    isPreview: isPreview(state),
    externalUserId: getExternalUserId(state),
    sessionId: getSessionId(state),
    actionId: getCurrentAction(state)?.id,
    canSkipLevel: canSkipLevel(state),
    inputMode: state.lesson.inputMode,
    alwaysCanSkipSteps: state.lesson.alwaysCanSkipSteps,
    isNonUserStep:
      currentAction?.__typename === "AvatarAction" || currentAction?.__typename === "SystemAction",
    isNonUserStepPaused: state.onPause,
  }
}
