import {useTranslation} from "react-i18next";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {useCurrentAccount, useCurrentUser, useSession} from "../App.context";
import useAdaptiveClasses from "../hooks/useAdaptiveClasses";
import {useSetExperimentalModeMutation} from "../App.graphql";
import {useSendBugReport} from "../hooks/telemetry";
import {useTour} from "../providers/tour";
import {useModal} from "./ModalProvider";
import {useHistory} from "react-router";
import {reverse} from "../routing";
import compact from "lodash/compact";
import {getHashCode, intToHSL} from "../utils";

import {DropdownRef} from "../ui/dropdown";
import DropdownList from "../ui/dropdownlist";
import Button from "../ui/button";
import {AlertCircleOutlineIcon, CaretDown, MapIcon} from "../ui/icons";
import ReactLink from "./ReactLink";
import Toggle from "../ui/toggle";
import BugReportModal from "./system/BugReportModal";

import cn from "classnames";
import classes from "./ProfileDropdown.module.css";
import {classes as tourClasses, isLayoutTourMock, SUBNAMES as tourSubnames} from "./Layout.tour";

type props = {
  adaptive?: boolean
}

export default function ProfileDropdown({adaptive}: props) {
  const {t} = useTranslation();

  const ac = useAdaptiveClasses(classes, adaptive)

  const ctxMenuRef = useRef<DropdownRef>(null);

  const user = useCurrentUser();
  const {name, email, id, fullName} = user ?? {};
  const account = useCurrentAccount();
  const history = useHistory();

  const {externalUserId} = useMemo(() => {
    const params = new URLSearchParams(window.location.search.substr(1));

    return {
      externalUserId: params.get("externalUserId") ?? null,
    };
  }, []);

  const {isAvailable: isTourAvailable, run: runTour, end: endTour, mock: tourMock, runningTourName} = useTour();

  const tourMode = runningTourName === tourSubnames.header;

  const onTourButtonClick = useCallback(() => {
    ctxMenuRef.current?.close();
    if (tourMode) {
      endTour(tourSubnames.header);
      setTimeout(() => runTour(undefined, true));
      return;
    }
    runTour();
  }, [tourMode, endTour, runTour])

  const mock = useMemo(() => {
    if (isLayoutTourMock(tourMock)) {
      return tourMock;
    }
  }, [tourMock]);

  const [setExperimentalModeMutation] = useSetExperimentalModeMutation();
  const [experimentalMode, setExperimentalMode] = useState(user?.experimentalMode);
  const toggleExperimentalMode = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setExperimentalMode(e.target.checked);
    setExperimentalModeMutation({
      variables: {
        state: e.target.checked
      }
    }).then(({data}) => {
      const userId = data?.user?.id;

      if (!userId) {
        return;
      }

      window.location.reload();
      return;
    });
  }, [setExperimentalModeMutation])

  const {set: setSessionId} = useSession();
  const logoutHandler = useCallback(() => {
    setSessionId(undefined);
    window.location.href = window.location.origin + reverse("login") + "?_aid=" + account?.id;
  }, [setSessionId, account]);

  const userColor = useMemo(() => {
    const userString = compact([name, email, id])[0]
    if (!userString) {
      return;
    }
    return intToHSL(getHashCode(userString))
  }, [name, email, id])

  const username = useMemo(() => (
    compact([name, email])[0]
  ), [name, email])

  const firstLetter = useMemo(() => (
    username?.charAt(0) ?? t("common.userLetter")
  ), [username, t])

  const sendBugReport = useSendBugReport();
  const {add: addModal} = useModal();

  const onSendReportButtonClick = useCallback(() => {
    ctxMenuRef.current?.close();

    const onBugReportSend = (message: string, attachment?: File, contact?: string) => {
      sendBugReport({message, attachment, contact, externalUserId, location: "profileDropdown"})
      modal.remove();
    }

    const modal = addModal({
      header: <BugReportModal.Header/>,
      content: (
        <BugReportModal.Content
          onBugReportSend={onBugReportSend}
        />
      ),
      stopCloseOnOuterClick: true,
    })
  }, [addModal, sendBugReport, externalUserId]);

  const onLoginButtonClick = useCallback(() => {
    history.push(reverse("login") + "?_next=" + encodeURIComponent(history.location.pathname));
  }, [history])

  const content = useMemo(() => (
    <>
      <div className={ac.profileInfo}>
        <div className={cn(ac.userInfo, tourClasses.userInfo)}>
          <div className={ac.userIcon} style={{backgroundColor: userColor}}>
            <span>{firstLetter}</span>
          </div>
          <div className={ac.userNameWrapper}>
            {(!(!user && externalUserId)) && (
              <span className={ac.userName}>{user ? fullName : t("components.ProfileDropdown.notLoggedIn")}</span>
            )}
            {name && <span className={ac.userSubName}>{email}</span>}
            {externalUserId && <span className={ac.userSubName}>exID: {externalUserId}</span>}
          </div>
        </div>
        {account && (
          <span className={tourClasses.account}><b>{t("components.ProfileDropdown.account")}:</b> {account.name}</span>
        )}
        {user?.accountsCount && user.accountsCount > 1 && (
          <Button className={ac.button} size="s" as={ReactLink} href={reverse("loginSelectAccount")}>
            {t("components.ProfileDropdown.selectAccount")}
          </Button>
        )}
        {user && (
          <Button className={ac.button} size="s" onClick={logoutHandler}>
            {t("components.ProfileDropdown.logout")}
          </Button>
        )}
        {!user && (
          <Button className={ac.button} size="s" onClick={onLoginButtonClick}>
            {t("components.ProfileDropdown.login")}
          </Button>
        )}
      </div>
      <div className={ac.separator}/>
      <DropdownList.Action
        className={cn(ac.action, ac.playTour, tourClasses.playTour)}
        icon={MapIcon}
        title={
          isTourAvailable
            ? t("components.ProfileDropdown.playTour")
            : t("components.ProfileDropdown.playTourDisabled")
        }
        disabled={!isTourAvailable}
        onClick={onTourButtonClick}
      />
      <DropdownList.Action
        className={cn(ac.action, tourClasses.sendBugReport)}
        icon={AlertCircleOutlineIcon}
        title={t("components.ProfileDropdown.sendBugReport")}
        onClick={onSendReportButtonClick}
      />
      {user?.isExperimental && (
        <>
          <div className={ac.separator}/>
          <div className={ac.experimentalMode}>
            {t("components.ProfileDropdown.experimentalMode")}
            <Toggle
              adaptive={adaptive}
              className={ac.toggle}
              checked={experimentalMode}
              onChange={toggleExperimentalMode}
            />
          </div>
        </>
      )}
    </>
  ), [
    ac, userColor, firstLetter, user, externalUserId, account, adaptive, email, experimentalMode,
    fullName, logoutHandler, name, isTourAvailable, onTourButtonClick, onLoginButtonClick,
    onSendReportButtonClick, t, toggleExperimentalMode
  ])

  return (
    <DropdownList
      notCloseOnClick
      stopCloseOnOuterClick={tourMode}
      adaptive={adaptive}
      dropShadow
      ref={ctxMenuRef}
      content={content}
      className={ac.dropdownMenu}
      placement="bottom-end"
      offset={[0, 8]}
    >
      <div className={cn(ac.root, tourClasses.profileDropdown)} onClick={mock?.eventHandlers.profileDropdownClick}>
        <Button className={ac.menuButton} color="primary" style={{backgroundColor: userColor}}>
          <span>{firstLetter}</span>
        </Button>
        <CaretDown className={ac.caretDown}/>
      </div>
    </DropdownList>
  )
}
