import {
  EducationSessionErrorReason,
  Lesson,
  Scalars
} from "../../schema";
import {Dispatch} from "redux";
import {ApolloClient} from "@apollo/client";
import {AudioManager} from "../../providers/audio";
import {PlayerReduxAction} from "./rootActions";
import {Action, Scenario} from "../../types";
import {UTM} from "../../libs/analytics";

export type PlayableAction = Action;

export type PlayerReduxState = {
  client: ApolloClient<object>, // GraphQL client
  audioManager: AudioManager, // audio playback manager
  toggleRecorder?: (state: boolean) => void, // microphone manager
  raiseProblem?: (key: string) => boolean, // raise problem to detect common failures

  askForCameraPermission?: boolean, // if true, user will be asked to give permission for webcam

  sessionId: Scalars["ID"] | null, // session id if started
  sessionStartFailed?: boolean // flag will be exist and true, if session not started
  sessionResumeFailed?: boolean // flag will be exist and true, if session not resumed

  lessonId: Scalars["ID"], // lesson id from URL, it might be different from lesson.id (contain security hash)
  lesson: Lesson, // lesson object
  scenarios: Scenario[], // actions objects

  isShared: boolean // is this shared-link session (anonymous)
  isPreview: boolean // is this preview
  isExternal: boolean // is external
  externalUserId: Scalars["ID"] | null // LMS user id
  recognitionEngine?: string // engine chosen for recognition
  recognitionPipeline?: string // recognize whole mediafile (old) or chunks (new)

  level: number // current level number
  index: number // current action index
  maxIndexes: number[] // max index of action reached for each scenario

  scenarioIndex: number // current scenario index, main lesson scenario is 0
  mainScenarioLastIndex: number // index of main scenario action that was last visited

  branchId?: Scalars["ID"]
  branchIndex?: number
  chosenBranches: Set<Scalars["ID"]> // branches in current action that were chosen before

  exposedActions: Scalars["ID"][] // action ids hint used for

  isRecognizing: boolean,
  isHintConfirmation: boolean,
  isAutoUseHintOffered?: boolean,

  interrupted: boolean,
  interruptReason: null | EducationSessionErrorReason,

  lastUserInputResult: {
    ok: boolean,
    recognizedText?: string,
    errorState: null | EducationSessionErrorReason,
  } | null,

  currentActionAttempts: number,

  feedbackSent: boolean,

  currentBackground: string | null,
  onPause: boolean,
  utm: UTM | null,
}

export type PlayerReduxDispatch = Dispatch<PlayerReduxAction>;

export enum UserInputDisplayMode {
  EXPECTED_TEXT,
  HINT_TEXT,
  SELECTABLE,
  TEXT_INPUT,
  TEXT_INPUT_SELECTABLE,
}
