import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import {
  ChoiceUserInputAction, ChoiceUserInputBranch, ChoiceUserInputBranchState, LessonInputMode, LessonMode
} from "../../../../schema";

import Content from "./Content";
import Phrase, {PhraseVariant} from "../../../Phrase";

import {useDispatch, useSelector} from "react-redux";
import {getLesson} from "../../../../redux/player/selectors";
import {PlayerReduxState} from "../../../../redux/player/types";
import {ChoiceUserInputDisplayMode} from "../../../../redux/player/selectors/getCurrentActionChoiceMode";

import classes from "./ChoiceUserInputActionContent.module.css";
import selectInput from "../../../../redux/player/actions/selectInput";

type ChoiceUserInputActionContentProps = {
  action: ChoiceUserInputAction
  phraseMode: PhraseVariant | undefined
  choiceMode: ChoiceUserInputDisplayMode | undefined
  chosenBranches: Set<string>
}

function ChoiceUserInputActionContent({
  action, phraseMode, choiceMode, chosenBranches
}: ChoiceUserInputActionContentProps) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const lesson = useSelector(ChoiceUserInputActionContent.selector, isEqual);
  const isTextInput = lesson.inputMode === LessonInputMode.TEXT;
  const isInputSelectable =
    lesson.mode === LessonMode.QUIZ ||
    lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES ||
    lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST ||
    ((lesson.mode === LessonMode.CHOICE_BASED_STUDY || lesson.mode === LessonMode.CHOICES_TEST_TWO_STEP) &&
    lesson.selectableInputs);
  const [isClickRegistered, registerClick] = useState(false);

  const branches: ChoiceUserInputBranch[] = useMemo(() => {
    switch (choiceMode) {
      case ChoiceUserInputDisplayMode.ALL:
      case ChoiceUserInputDisplayMode.ALL_WITH_HINT:
        return action.branches;
      case ChoiceUserInputDisplayMode.ONLY_VALID:
        return action.branches.filter(b => b.state === ChoiceUserInputBranchState.CORRECT);
      default:
        console.error("NotImplemented");
        return [];
    }
  }, [action.branches, choiceMode])

  const onContentClick = useCallback((e: React.MouseEvent) => {
    const branchIndex = parseInt(e.currentTarget.getAttribute("data-index")!);
    if (isInputSelectable && !isClickRegistered) {
      registerClick(true);
      dispatch(selectInput(branchIndex));

      if ((lesson.mode === LessonMode.CHOICE_BASED_STUDY || lesson.mode === LessonMode.CHOICES_TEST_TWO_STEP) &&
      lesson.selectableInputs) {
        registerClick(false);
      }
    }
  }, [dispatch, isInputSelectable, isClickRegistered, lesson.mode, lesson.selectableInputs])

  const help = useMemo(() => {
    if (isTextInput) {
      if (isInputSelectable) {
        return t("player.choiceUserInputAction.text.helpTextAlt")
      } else {
        return t("player.choiceUserInputAction.text.helpText")
      }
    } else {
      if (isInputSelectable) {
        return t("player.choiceUserInputAction.voice.helpTextAlt")
      } else {
        return t("player.choiceUserInputAction.voice.helpText")
      }
    }
  }, [isTextInput, isInputSelectable, t])

  return (
    <>
      <Content variant="help">
        {help}
      </Content>
      {branches.map((b, index) => (
        <Content
          data-index={index}
          key={b.id}
          variant="user"
          onClick={onContentClick}
        className={
          chosenBranches?.has(b.id) && (
              b.state !== ChoiceUserInputBranchState.CORRECT
            )
            ? classes.incorrectChoice
            : undefined
        }>
          <Phrase
            text={b.userInput.hintText ? b.userInput.hintText : b.userInput.expectedText}
            variant={phraseMode ?? PhraseVariant.DEFAULT}
            italic={!!b.userInput.hintText}
          />
        </Content>
      ))}
    </>
  )
}

export default ChoiceUserInputActionContent;

ChoiceUserInputActionContent.selector = (state: PlayerReduxState) => {
  return getLesson(state)
}
