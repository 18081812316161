import React from "react";
import {useTranslation} from "react-i18next";
import Widget from "../../ui/widget";

import classes from "./ProblemsWidget.module.css"

type props = Omit<React.ComponentProps<typeof Widget>, "onBugReportSend" | "as" | "children"> & {
  onClose?: () => void,
}

export function NetworkWidget({title, onClose, ...props}: props) {
  const {t} = useTranslation();

  return (
    <Widget
      className={classes.root}
      showCloseButton
      onCloseButtonClick={onClose}
      {...props}
    >
      <Widget.Title>{t("components.system.NetworkWidget.title")}</Widget.Title>
      <Widget.Description>{t("components.system.NetworkWidget.description")}</Widget.Description>
    </Widget>
  )
}

export default function WithNetworkWidget({children, showWidget, ...props}: props & {
  children: React.ReactNode,
  showWidget?: boolean
}) {
  return (
    <>
      {children}
      {showWidget && (
        <NetworkWidget {...props}/>
      )}
    </>
  )
}